import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import {
  SubscribeContainer,
  SubscribeContents,
  SubscribeEmailForm,
} from 'components/NewsLetterComponents'

const Blog = () => {
  const { t } = useTranslation('newsletter')

  /**
   * Displays a very basic, generic, error message. Used to handle general errors.
   */
  const displayBasicErrorHandling = (error = 'NA') =>
    toast.error(t('genericError'))

  /**
   * @param {object} e the event object, will contain the form data from the subscription form
   */
  function isEmailValid(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  /**
   * Processes the form data when the user clicks the "Submit" button.
   *
   * @param {object} e the event object, will contain the form data from the subscription form
   */
  async function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault()

    // Read the form data
    const form = e.target
    const formData = new FormData(form)

    const formJson = Object.fromEntries(formData.entries())
    // Remove these logs
    console.log(formJson)

    // Validate form data (email) and respond to user accordingly
    if (!isEmailValid(formJson.email)) {
      toast.error(t('invalidEmail'))
      return
    }

    // send the form data to Mailchimp
    console.log('posting...')
    addToMailchimp(formJson.email)
      .then((response) => {
        console.log('response: ', response)
        toast.success(t('successfulSubscribe'))
      })
      .catch((error) => {
        console.error('Error: ', error)
        displayBasicErrorHandling()
      })
  }

  return (
    <SubscribeContainer>
      <SubscribeContents>
        <SubscribeEmailForm>
          <form method="post" onSubmit={handleSubmit}>
            <input
              type="text"
              id="email"
              name="email"
              defaultValue="john.doe@email.com"
            />
            <input type="submit" value={t('subscribeButton')} />
          </form>
        </SubscribeEmailForm>
      </SubscribeContents>
    </SubscribeContainer>
  )
}

export default Blog
