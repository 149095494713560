import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

export const SubscribeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
`

export const SubscribeContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const SubscribeEmailForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  form {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    padding: 10px;

    ${mq['sm']} {
      flex-direction: column;
    }
  }

  input[type='text'],
  select {
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 5px;
  }

  input[type='submit'] {
    background-image: linear-gradient(to right, #00daa0 0%, #00b7ff 100%);
    color: white;
    padding: 14px 20px;
    margin: 8px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      transform: scale(1.02);
    }
  }

  input[type='submit']:hover {
    background-color: #45a049;
  }
`
